import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactUsService from "./contactusService";

const initialState = {
  contact: [],
  imgUrl: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All contactus
export const contactUs = createAsyncThunk(
  "contactUs",
  async (formData, thunkAPI) => {
    try {
      return await contactUsService.contact(formData);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const contactusSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(contactUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.contact = null;
      });
  },
});

export const { reset } = contactusSlice.actions;
export default contactusSlice.reducer;
