import axios from "axios";
import { linkUrl } from "../linkurl";

const API_URL = linkUrl;

// Get All Products
const valueData = async () => {
  const res = await axios.get(`${API_URL}/value`);
  if (res.data.status === 200) {
    return res.data.data.value;
  }
};

const valuesService = {
  valueData,
};
export default valuesService;
