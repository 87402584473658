import axios from "axios";
import { linkUrl } from "../linkurl";

const API_URL = linkUrl;

// Get All Products
const setting = async () => {
  const res = await axios.get(`${API_URL}/sitesetting`);
  if (res.data.status === 200) {
    return res.data.data;
  }
};

const settingService = {
  setting,
};
export default settingService;
