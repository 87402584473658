import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import destinationService from "./destinationService";

const initialState = {
  destinations: [],
  imgUrl: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Destinations
export const destination = createAsyncThunk(
  "destinations",
  async (thunkAPI) => {
    try {
      return await destinationService.destinations();
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const destinationUsSlice = createSlice({
  name: "destinations",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(destination.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(destination.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.destinations = action.payload.destination;
        state.imgUrl = action.payload.imgurl;
      })
      .addCase(destination.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.destinations = null;
      });
  },
});

export const { reset } = destinationUsSlice.actions;
export default destinationUsSlice.reducer;
