import React from "react";
import { useSelector } from "react-redux";
import FutureDestinations from "./FutureDestinations";

const Destination = ({ destination }) => {
  const { destinations, imgUrl } = useSelector((state) => state.destinations);

  let destinationData =
    destinations &&
    destinations.map((destination) =>
      destination && destination.type === "current"
        ? destination &&
          destination.destination &&
          destination.destination.map((dest, idx) => (
            <div className="col-md-4 mb-3" key={idx}>
              <div className="card text-center p-2">
                {dest && dest.country_flag ? (
                  <img
                    src={`${imgUrl}/${dest.country_flag}`}
                    alt={dest.country_name}
                    width="100%"
                    height="250px"
                    className="mx-auto d-block"
                  />
                ) : (
                  ""
                )}

                <div className="card-body flag-heading">
                  <h1 className="card-title">{dest.country_name}</h1>
                </div>
              </div>
            </div>
          ))
        : ""
    );

  return (
    <div className="container mt-4" ref={destination}>
      <section className="mt-50 mb-50">
        <div className="row text-center">
          <h1>Space Voyage Through</h1>
          {/* <h2>Current Adventure:</h2> */}
        </div>
        <div className="row">
          <h3 className="text-end">21 Days in</h3>
        </div>
        <div className="row">
          {/*  */}
          {destinationData}
        </div>
      </section>

      {/* <FutureDestinations /> */}
    </div>
  );
};

export default Destination;
