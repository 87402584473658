import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import coverpageService from "./coverpageService";

const initialState = {
  coverpage: {},
  imgUrl: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All setting
export const coverPage = createAsyncThunk("coverPage", async (thunkAPI) => {
  try {
    return await coverpageService.coverpage();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const coverpageSlice = createSlice({
  name: "coverpage",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(coverPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(coverPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.coverpage = action.payload.cover;
        state.imgUrl = action.payload.imgurl;
      })
      .addCase(coverPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.setting = null;
      });
  },
});

export const { reset } = coverpageSlice.actions;
export default coverpageSlice.reducer;
