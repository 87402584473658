import { useRef } from "react";

import { Routes, Route } from "react-router-dom";

import BlogLists from "./components/pages/blogs/BlogLists"
import BlogDetails from "./components/pages/blogs/BlogDetails";

import Layout from "./components/layout/Layout";
import HomePageCollections from "./components/layout/homepage/HomePageCollections";
import PageNotFound from "./components/pages/PageNotFound";

function App() {
  const homepage = useRef(null);
  const aboutpage = useRef(null);
  const destination = useRef(null);
  const contactpage = useRef(null);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              homepage={homepage}
              aboutpage={aboutpage}
              destination={destination}
              contactpage={contactpage}
            />
          }
        >
          <Route
            path="/"
            element={
              <HomePageCollections
                homepage={homepage}
                aboutpage={aboutpage}
                destination={destination}
                contactpage={contactpage}
              />
            }
          />
          <Route path="/blogs" element={<BlogLists />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
        </Route>
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </>
  );
}

export default App;
