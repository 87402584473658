import React from "react";
import { useSelector } from "react-redux";

const BlogLists = ({ blogpage }) => {
  const { blogs, imgUrl } = useSelector((state) => state.blogs);

  let blogList =
    blogs &&
    blogs.map((blog, idx) => (
      <div className="col-lg-3" key={idx}>
        <article className="wow fadeIn animated hover-up mb-30">
          <div className="post-thumb img-hover-scale">
            {blog && blog.blog_img ? (
              <a href={`/blog/${blog.id}`} target="_blank"
              rel="noopener noreferrer">
                <img src={`${imgUrl}/${blog.blog_img}`} alt={blog.blog_title} />
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="entry-content-2">
            <h4 className="post-title mb-15">
              <a href={`/blog/${blog.id}`} target="_blank"
                  rel="noopener noreferrer">{blog.blog_title}</a>
            </h4>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  blog && blog.blog_des
                    ? blog.blog_des.substring(0, 170) + " ..."
                    : "",
              }}
            />

            <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
              <a href={`/blog/${blog.id}`} target="_blank"
                  rel="noopener noreferrer" className="text-brand">
                Read more <i className="fi-rs-arrow-right"></i>
              </a>
            </div>
          </div>
        </article>
      </div>
    ));

  return (
    <main className="main" ref={blogpage}>
      <section style={{marginTop:"100px"}}>
        <div className="container custom">
          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="single-header mb-50 text-center">
                <h1 className="font-xxl text-brand">Our Blog</h1>
              </div>
              <div className="loop-grid pr-30">
                <div className="row">{blogList}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BlogLists;
