import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/Header";

const Layout = ({
  homepage,
  aboutpage,
  destination,
  blogpage,
  contactpage,
}) => {
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <main className="container">
      <Header
        scrollToSection={scrollToSection}
        homepage={homepage}
        aboutpage={aboutpage}
        destination={destination}
        blogpage={blogpage}
        contactpage={contactpage}
      />
      <Outlet />
    </main>
  );
};

export default Layout;
