import { configureStore } from "@reduxjs/toolkit";
import aboutReducer, { aboutUs } from "./aboutus/aboutUsSlice";
import blogReducer, { blogs } from "./blogs/blogSlice";
import contactusReducer from "./contactus/contactusSlice";
import coverpageReducer, { coverPage } from "./coverpage/coverpageSlice";
import destinationReducer, {
  destination,
} from "./destinations/destinationSlice";

import settingReducer, { siteSetting } from "./sitesetting/settingSlice";
import dataValuesReducer, { valuesData } from "./dataValues/valuesSlice";

export const store = configureStore({
  reducer: {
    abouts: aboutReducer,
    blogs: blogReducer,
    contactus: contactusReducer,
    cover: coverpageReducer,
    destinations: destinationReducer,
    setting: settingReducer,
    valuesData: dataValuesReducer,
  },
});

store.dispatch(siteSetting());
store.dispatch(aboutUs());
store.dispatch(coverPage());
store.dispatch(destination());
store.dispatch(blogs());

store.dispatch(valuesData());
