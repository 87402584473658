import React from "react";
import { useSelector } from "react-redux";

const Banner = ({ closeModal }) => {
  const { coverpage } = useSelector((state) => state.cover);

  return (
    <>
      <div className="row">
        <div className="color-overlay d-flex justify-content-center">
          <h1>{coverpage.title}</h1>
        </div>
      </div>

      <div className="row">
        <div className="masthead-img">
          <img
            src="assets/imgs/logo/logo.png"
            className="mx-auto d-block"
            style={{ width: "150px" }}
            alt={coverpage.title}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md mt-4">
          <div className="logo-bottomText">
            <p>{coverpage.des}</p>
            <h1
              type="button"
              onClick={closeModal}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {coverpage.sub_title}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
