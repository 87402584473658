import React from "react";
import { useSelector } from "react-redux";
import EmailComponent from "../../email/EmailComponent";

const ConnectWithUs = () => {
  const { setting } = useSelector((state) => state.setting);
  return (
    <>
      <div className="row">
        <div className="container p-3">
          <h3 className="text-center">Our Contact</h3>
          <div className="contact">
            <ul>
              <li>
                Contact No.:{" "}
                <a
                  href={`tel:${
                    setting && setting.site_phone ? setting.site_phone : ""
                  }`}
                  style={{ textDecoration: "none", color: "#023d45" }}
                >
                  {setting && setting.site_phone ? setting.site_phone : ""}
                </a>{" "}
              </li>
              <li>
                E-mail:{" "}
                <a
                  href={`mailto:${
                    setting && setting.site_email ? setting.site_email : ""
                  }`}
                  style={{ textDecoration: "none", color: "#023d45" }}
                >
                  {setting && setting.site_email ? setting.site_email : ""}
                </a>
              </li>
              <li>
                Address:{" "}
                {setting && setting.site_address ? setting.site_address : ""}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="row my-3">
          <h3 className="text-center">Follow Us on</h3>
        </div>
        <div className="mt-4 d-flex justify-content-around">
          {setting && setting.site_fb_link ? (
            <div className="social-icons">
              <a
                href={setting.site_fb_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="assets/imgs/social/linkedin.png" alt="linkedin" />
                {/* <img src="assets/imgs/social/fb.png" alt="facebook" /> */}
              </a>
            </div>
          ) : (
            ""
          )}

          {/* {setting && setting.site_in_link ? (
            <div className="social-icons">
              <a
                href={setting.site_in_link}
                target="_blank"
                rel="noopener,noreferrer"
              >
                <img src="assets/imgs/social/insta.png" alt="instagram" />
              </a>
            </div>
          ) : (
            ""
          )} */}

          {setting && setting.pinterest ? (
            <div className="social-icons">
              <a
                href={setting.pinterest}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="assets/imgs/social/pinterest.png" alt="pinterest" />
              </a>
            </div>
          ) : (
            ""
          )}

          {setting && setting.site_lin_link ? (
            <div className="social-icons">
              <a
                href={setting.site_lin_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="assets/imgs/social/linkedin.png" alt="linkedin" />
              </a>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="row my-3">
          {/* <div className="col-md-6">
            <h5 className="text-center">In Partnership With:</h5>
            <div className="d-flex justify-content-center">
              <a
                href="https://www.instagram.com/memorynepaltt/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="assets/imgs/partners/memorynepal.png"
                  alt="memorynepal"
                />
              </a>
            </div>
          </div> */}
          <div className="col-md-6">
            <h5 className="text-center">Powered By:</h5>
            <div className="d-flex justify-content-center">
              <div className="text-center">
                <a
                  href="https://blackhawk.edu.np"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/imgs/partners/blackhawk.png"
                    alt="blackhawk"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <EmailComponent setting={setting} />
      </div>
    </>
  );
};

export default ConnectWithUs;
