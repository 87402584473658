import React, { useEffect } from "react";
import Banner from "./Banner";

const QuickView = () => {
  const bootstrap = window.bootstrap;

  useEffect(() => {
    var myModal = new bootstrap.Modal(document.getElementById("onloadModal"), {
      keyboard: false,
      backdrop: "static",
      focus: true,
    });
    myModal.show();
  }, [bootstrap.Modal]);

  const closeModal = () => {
    var myModal = bootstrap.Modal.getInstance(
      document.getElementById("onloadModal")
    );
    myModal.hide();
  };

  return (
    <>
      {/* <!-- Modal --> */}
      <div
        className="modal fade custom-modal"
        id="onloadModal"
        tabIndex="-1"
        aria-labelledby="onloadModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content masthead">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              <Banner closeModal={closeModal} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickView;
