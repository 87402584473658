import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import aboutService from "./aboutService";

const initialState = {
  abouts: [],
  imgUrl: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All aboutus
export const aboutUs = createAsyncThunk("aboutus", async (thunkAPI) => {
  try {
    return await aboutService.abouts();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const aboutUsSlice = createSlice({
  name: "aboutus",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(aboutUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(aboutUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.abouts = action.payload.aboutUs;
        state.imgUrl = action.payload.imgurl;
      })
      .addCase(aboutUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.abouts = null;
      });
  },
});

export const { reset } = aboutUsSlice.actions;
export default aboutUsSlice.reducer;
