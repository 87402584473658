import React from "react";
import { useSelector } from "react-redux";

const Header = ({
  scrollToSection,
  homepage,
  aboutpage,
  destination,
  blogpage,
  contactpage,
}) => {
  const { setting, imgUrl } = useSelector((state) => state.setting);

  return (
    <>
      <div className="container">
        <nav className="navbar fixed-top navbar-light bg-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img
                src="/favicon.png"
                alt=""
                width="45"
                height="45"
                className="d-inline-block"
              />
              <span>
                {setting && setting.site_title ? setting.site_title : ""}
              </span>
            </a>

            <button
              className="navbar-toggler collapsed d-flex flex-column justify-content-around"
              type="button"
              id="toggleButton"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="toggler-icon top-bar"></span>
              <span className="toggler-icon middle-bar"></span>
              <span className="toggler-icon bottom-bar"></span>
            </button>
          </div>
        </nav>

        {/* Off canvase side drawer */}

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNav"
        >
          <div className="offcanvas-header">
            {setting && setting.site_fav ? (
              <a className="navbar-brand" href="/">
                <img
                  src={`${imgUrl}/fav/${setting.site_fav}`}
                  alt=""
                  width="45"
                  height="45"
                  className="d-inline-block align-text-top"
                />
              </a>
            ) : (
              ""
            )}
            <a className="navbar-brand" href="/">
              <h3 className="offcanvas-title" id="offcanvasNav">
                {setting && setting.site_title ? setting.site_title : ""}
              </h3>
            </a>
            <button
              type="button"
              className="navbar-toggler collapsed d-flex flex-column justify-content-around"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="navCollapse"
            >
              <span className="toggler-icon top-bar"></span>
              <span className="toggler-icon middle-bar"></span>
              <span className="toggler-icon bottom-bar"></span>
            </button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav">
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target="#navCollapse"
                onClick={() => scrollToSection(homepage)}
                data-bs-dismiss="offcanvas"
              >
                <a href="/">Home</a>
              </li>
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target="#navCollapse"
                onClick={() => scrollToSection(aboutpage)}
                data-bs-dismiss="offcanvas"
              >
                <a href="/">About Us</a>
              </li>
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target="#navCollapse"
                onClick={() => scrollToSection(destination)}
                data-bs-dismiss="offcanvas"
              >
                <a href="/">Space Voyage Through</a>
              </li>
              
              <li className="nav-item"  data-bs-dismiss="offcanvas">
                <a href="/blogs" target="_blank" rel="noopener noreferrer">Blogs</a>
              </li>
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target="#navCollapse"
                onClick={() => scrollToSection(contactpage)}
                data-bs-dismiss="offcanvas"
              >
                <a href="/">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
