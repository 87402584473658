import React from "react";
import { useSelector } from "react-redux";

const AboutUs = ({ aboutpage }) => {
  const { abouts, imgUrl } = useSelector((state) => state.abouts);

  let aboutData =
    abouts &&
    abouts.map((about, idx) => (
      <div className="abt-dec" key={idx}>
        {about && about.about_img ? (
          <>
            <img
              src={`${imgUrl}/${about.about_img}`}
              alt="aboutImg"
              width="320"
              height="400"
              className="d-inline-block"
            />
          </>
        ) : (
          ""
        )}
        <p dangerouslySetInnerHTML={{ __html: about.about_des }}></p>
      </div>
    ));
  return (
    <div className="container" ref={aboutpage}>
      <div className="row about">
        <h1 style={{ textAlign: "center" }}>Our Products</h1>
        <div className="row ">{aboutData}</div>
      </div>
    </div>
  );
};

export default AboutUs;
