import React from "react";
import ConnectWithUs from "./ConnectWithUs";
import ContactForm from "./ContactForm";

const Contact = ({ contactpage }) => {
  return (
    <section className="mt-4" ref={contactpage}>
      <div className="container">
        <div className="row">
          <h1>Get In Touch</h1>
          <div className="col-md-6">
            <div className="row text-center">
              <h2>Let Us Know</h2>
            </div>
            <div className="d-flex justify-content-center">
              <div className="row">
                <ContactForm />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row text-center">
              <h2>Other Way To Connect</h2>
            </div>

            <ConnectWithUs />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
