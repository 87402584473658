import axios from "axios";
import { linkUrl } from "../linkurl";

const API_URL = linkUrl;

// Get All Products
const blogs = async () => {
  const res = await axios.get(`${API_URL}/blog`);
  if (res.data.status === 200) {
    return res.data.data;
  }
};

const blog = async (blogId) => {
  const res = await axios.get(`${API_URL}/blog/${blogId}`);
  if (res.data.status === 200) {
    return res.data.data;
  }
};

const blogService = {
  blogs,
  blog,
};
export default blogService;
