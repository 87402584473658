import React from "react";
import { useSelector } from "react-redux";

const HomePage = ({ homepage }) => {
  // const { destinations, imgUrl } = useSelector((state) => state.destinations);
  const { dataValues, isLoading } = useSelector((state) => state.valuesData);

  if (isLoading) {
    return "";
  }

  let fetchData =
    dataValues &&
    dataValues.map((dataValue, idx) => (
      <h2 className="text-center" key={idx}>
        {dataValue.title}
      </h2>
    ));

  return (
    <div className="row homepage" ref={homepage}>
      {/* <h1>This is Home Page</h1> */}
      <div className="col-md-6 mb-4">
        <div className="row">
          <span className="home-logo text-center">
            <img
              src="assets/imgs/logo/logo.png"
              className="img-fluid mt-4"
              alt="voyage ride"
            />
          </span>
        </div>
      </div>
      <div className="col-md-6 mt-3">
        <div className="homepage-value">{fetchData}</div>
      </div>
      {/* <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="">
              <div className="homepage-img">
                <img
                  src="assets/imgs/logo/logo.png"
                  className="img-fluid mt-4"
                  alt="voyage ride"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="text-center">
              <div className="homepage-value">{fetchData}</div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default HomePage;
