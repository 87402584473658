import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { contactUs } from "../../../redux/contactus/contactusSlice";

const ContactForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      address: "",
      message: "",
    },
  });

  const onSubmit = (data) => {
    if (data !== undefined || data !== null) {
      dispatch(contactUs(data));

      reset();
    }
  };

  // console.log("form Data", formData);

  return (
    <form className="row g-3 mb-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="col-md-6">
        <label htmlFor="fullName" className="form-label">
          Full Name
        </label>
        <input
          type="text"
          className="form-control"
          name="name"
          placeholder="Full Name"
          {...register("name", { required: true })}
        />
        {errors.name && (
          <p className="text-danger">Please provide your full name.</p>
        )}
      </div>

      <div className="col-md-6">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="abc@example.com"
          {...register("email", {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
        />
        {errors.email && (
          <p className="text-danger">Email field is required.</p>
        )}
      </div>

      <div className="col-12">
        <label htmlFor="address" className="form-label">
          Address
        </label>
        <input
          type="text"
          name="address"
          className="form-control"
          placeholder="1234 Main St"
          {...register("address", { required: true })}
        />
        {errors.address && (
          <p className="text-danger">Address field is required.</p>
        )}
      </div>

      <div className="col-12">
        <label htmlFor="message" className="form-label">
          Message
        </label>
        <div className="form-floating">
          <textarea
            className="form-control"
            name="message"
            placeholder="Leave a comment here"
            style={{ height: "100px" }}
            {...register("message", { required: true })}
          ></textarea>
          <label htmlFor="message">Your Message</label>
        </div>
        {errors.message && (
          <p className="text-danger">Message field is required.</p>
        )}
      </div>

      <div className="col-12">
        <div className="d-grid">
          <input className="btn" type="submit" />
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
