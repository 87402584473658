import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import blogService from "./blogService";

const initialState = {
  blogs: [],
  blog: {},
  imgUrl: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All blogs
export const blogs = createAsyncThunk("blogs", async (thunkAPI) => {
  try {
    return await blogService.blogs();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get All single blog
export const blogDetails = createAsyncThunk(
  "blog",
  async (blogId, thunkAPI) => {
    try {
      return await blogService.blog(blogId);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(blogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(blogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blogs = action.payload.bloglist;
        state.imgUrl = action.payload.imgurl;
      })
      .addCase(blogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.blogs = null;
      })

      // Single Blog
      .addCase(blogDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(blogDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blog = action.payload.blog;
        state.imgUrl = action.payload.imgurl;
      })
      .addCase(blogDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.blog = null;
      });
  },
});

export const { reset } = blogSlice.actions;
export default blogSlice.reducer;
