import React from "react";

import AboutUs from "../../pages/AboutUs";
import Contact from "../../pages/contact/Contact";
import Destination from "../../pages/Destination";
import HomePage from "../../pages/HomePage";
import QuickView from "../../quickView/QuickView";

const HomePageCollections = ({
  homepage,
  aboutpage,
  destination,
  blogpage,
  contactpage,
}) => {
  return (
    <>
      <QuickView />
      <HomePage homepage={homepage} />
      <AboutUs aboutpage={aboutpage} />
      <Destination destination={destination} />
      <Contact contactpage={contactpage} />
    </>
  );
};

export default HomePageCollections;
