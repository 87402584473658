import axios from "axios";
import { linkUrl } from "../linkurl";

const API_URL = linkUrl;

// Get All Products
const destinations = async () => {
  const res = await axios.get(`${API_URL}/destination`);
  if (res.data.status === 200) {
    return res.data.data;
  }
};

const destinationService = {
  destinations,
};
export default destinationService;
