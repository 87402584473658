import axios from "axios";
import { linkUrl } from "../linkurl";

const API_URL = linkUrl;

// Get All Products
const abouts = async () => {
  const res = await axios.get(`${API_URL}/about`);
  if (res.data.status === 200) {
    return res.data.data;
  }
};

const aboutService = {
  abouts,
};
export default aboutService;
