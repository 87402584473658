import axios from "axios";
import { linkUrl } from "../linkurl";

const API_URL = linkUrl;

// Get All Products
const contact = async (formData) => {
  console.log(formData);
  const res = await axios.post(`${API_URL}/contactus`, formData);
  if (res.data.status === 200) {
    return res.data.message;
  }
};

const contactUsService = {
  contact,
};
export default contactUsService;
