import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { blogDetails } from "../../../redux/blogs/blogSlice";
import moment from "moment";

const BlogDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { blog, imgUrl } = useSelector((state) => state.blogs);

  useEffect(() => {
    if (id !== undefined || id !== null) {
      dispatch(blogDetails(id));
    }
  }, [dispatch, id]);

  let blogDate =
    blog && blog.created_at
      ? moment(blog.created_at).utc().format("MMM Do YY")
      : "";

  return (
    <>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="/" rel="nofollow">
                Home
              </a>
              <span></span> Blog
            </div>
          </div>
        </div>
        <section className="mt-50 mb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="single-page pl-30">
                  <div className="single-header style-2">
                    <h1 className="mb-30">
                      {blog && blog.blog_title ? blog.blog_title : ""}
                    </h1>
                    <div className="single-header-meta">
                      <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                        <span className="post-by">
                          By{" "}
                          <span className="text-success">
                            {blog && blog.blog_author ? blog.blog_author : ""}
                          </span>
                        </span>
                        <span className="post-on has-dot">{blogDate}</span>
                      </div>
                    </div>
                  </div>
                  <figure className="single-thumbnail">
                    {blog && blog.blog_img ? (
                      <img
                        src={`${imgUrl}/${blog.blog_img}`}
                        alt={blog.blog_title}
                      />
                    ) : (
                      ""
                    )}
                  </figure>
                  <div className="single-content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blog && blog.blog_des ? blog.blog_des : "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default BlogDetails;
