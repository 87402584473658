import React from "react";

const EmailComponent = ({ setting }) => {
  return (
    <div className="fixedbottom">
      {setting && setting.site_email ? (
        <a
          href={`mailto:${
            setting && setting.site_email ? setting.site_email : ""
          }`}
          type="button"
        >
          <img
            src="assets/imgs/social/webmail.png"
            alt="webmail"
            width="100px"
          />
        </a>
      ) : (
        ""
      )}
    </div>
  );
};

export default EmailComponent;
